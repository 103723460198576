
<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Home',

    metaInfo: {
      title: 'Home',
      titleTemplate: 'RH Robotics',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { vmid: 'keyword', name: 'keyword', content: 'RH ROBOTICS, 롱하오 로봇 테크놀로지, 로봇, 자동화, 오토메이션, 롱하오, 지능형, 솔루션, 공장, 공장 자동화, 말레이시아, 중국, 한국, 인도네시아, 태국, 유럽, 창고, 웨어하우스, 스마트 웨어하우스, 지능형 창고, 시스템 통합, 시뮬레이션, 물류, 산업혁명4.0, 기계 자동화, 인건비 절약, 컨베이어, 소터, 창고관리 시스템, 창고제어시스템, 창고실행시스템, 생산관리시스템, 엔지니어링, 컨설팅, Ronghao Robot Technology, robot, automation, technology, ronghao, intelligent, solution, provider, factory, smart factory, Malaysia, south-east asia, southeast asia, asia, Indonesia, Tailand, Vietnam, Taiwan, Singapore, China, Warehouse, Smart Wearhouse, IT system, SI, System Integration, ERP, WMS, WCS, MES, simulation, Robotic technology, Intelligent manufacturing, storage, logistics, Goods To People, Software Integration, Industry revolution 4.0, industrial upgrading, Information TECHNOLOGY, Mechanical automation, centralized operations, time saving, unit loading, labor force reduction, 3 dimensional storage, streamline process, shuttle rack, AS/RS, GTP, Mini Load, Pallet Automatic Warehouse, DPS, DAS, Digital Picking System, Digital Assortment System, Conveyor, Sorter, Vertical Carrier, AGV, Arc Welding, 3d Vision Scanner, Enterprise Resource Planning, Warehouse Management System, Warehouse Control System, Manufacturing Execution System, CONSULTING, ENGINEERING, PORTFOLIO, IMPLEMENTED PROJECTS, Auxiliary lines, BROCHURE' },
        { vmid: 'description', name: 'description', content: 'RONGHAO (SHANDONG) ROBOT TECHNOLOGY CO., LTD.는 산둥성 지보시 하이테크 존 칭룽산로 9009호에서 2021년 6월에 설립되었습니다. 당사는 자동화 기술 및 로봇 기술을 통한 스마트 공장 및 스마트 물류 솔루션을 제공합니다. 고객에게 최적의 비용 효율적이고 신뢰할 수 있는 제조 및 물류 솔루션을 제공하여 해당 업계에서 최고의 우위를 점할 수 있다고 확신합니다. 로봇 기술과 결합된 자동화는 인간의 실수를 줄일 뿐만 아니라 수율과 제조 생산성을 크게 향상시킬 것입니다. RONGHAO (SHANDONG) ROBOT TECHNOLOGY CO., LTD.에서 자동화 및 인공 지능의 힘을 활용하여 비즈니스를 혁신하십시오.' },
      ],
    },

    extends: View,

    mixins: [
      LoadSections([
        'main-howellcare',
        'about-our-product',
        'main-desc',
        'banner',
        'slogan',
        'before-footer',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'home',
      },
    },
  }
</script>
